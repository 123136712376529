import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgBailarina, ImgBailarina, ContImgPerro, ImgPerro} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import useSound from 'use-sound';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import imgBailarina from '../../../images/obras/obra-11/bailarina.png';
import imgPerro from '../../../images/obras/obra-11/perro.png';
import soundBailarina from '../../../images/obras/obra-11/baile.mp3';
import soundPerro from '../../../images/obras/obra-11/bark.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 12,
  bottom: null,
  left: 10,
};

const Page11Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedBailarina, setInitAnimatedBailarina] = useState(false);
  const [initAnimatedPerro, setInitAnimatedPerro] = useState(false);
  const [initSoundBailarina, setInitSoundBailarina] = useState(false);
  const [initSoundPerro, setInitSoundPerro] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-11/obra.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchBailarina(0);
    fnReLaunchPerro(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchBailarina = (noReplay = 1) => {
    setInitAnimatedBailarina(false);
    setInitSoundBailarina(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedBailarina(true);
      }, 1000);
    }
  };
  const fnReLaunchPerro = (noReplay = 1) => {
    setInitAnimatedPerro(false);
    setInitSoundPerro(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedPerro(true);
      }, 1000);
    }
  };

  const fnAnimateBailarina = () => {
    return (
      <ContImgBailarina className={'img-animated'} onClick={() => fnReLaunchBailarina()}>
        {
          initAnimatedBailarina &&
          <ImgBailarina
            id={'animate-bailarina'}
            className={'animate-bailarina'}
            src={imgBailarina}
            alt="Luna"
          />
        }
      </ContImgBailarina>
    )
  }

  const fnAnimatePerro = () => {
    return (
      <ContImgPerro className={'img-animated'} onClick={() => fnReLaunchPerro()}>
        {
          initAnimatedBailarina &&
          <ImgBailarina
            id={'animate-perro'}
            className={'animate-perro'}
            src={imgPerro}
            alt="Perro"
          />
        }
      </ContImgPerro>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Mi padre iba y venía entre las llamas.</div>
            <div>Por los durmientes y los rieles</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const [playBailarina, { stopBailarina }] = useSound(
    soundBailarina,
    { volume: 0.3 }
  );
  const [playPerro, { stopPerro }] = useSound(
    soundPerro,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedBailarina(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedPerro(true);
    }, 6000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 9000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosBailarina = {};
    let arPosPerro = {};

    if(winWidth < 767){
      arPosBailarina = {x: 130}
      arPosPerro = {x: 80}
    } else if(winWidth < 1023){
      arPosBailarina = {x: 200}
      arPosPerro = {x: 165}
    } else if(winWidth < 1215){
      arPosBailarina = {x: 300}
      arPosPerro = {x: 180}
    } else if(winWidth < 1407){
      arPosBailarina = {x: 400}
      arPosPerro = {x: 410}
    } else if(winWidth < 1919){
      arPosBailarina = {x: 400}
      arPosPerro = {x: 410}
    } else {
      arPosBailarina = {x: 600}
      arPosPerro = {x: 600}
    }

    if(initAnimatedBailarina){
      gsap.to(".animate-bailarina", {
        rotationY: 180,
        delay: 1
      });
      gsap.to(".animate-bailarina", {
        rotationY: 0,
        delay: 2
      });
      gsap.to(".animate-bailarina", {
        rotationY: 180,
        delay: 3
      });
      gsap.to(".animate-bailarina", {
        rotationY: 0,
        delay: 4
      });
      gsap.to(".animate-bailarina", {
        x: arPosBailarina.x,
        opacity: 0,
        delay: 5,
        duration: 3,
      });

      if(!initSoundBailarina){
        playBailarina();
        setInitSoundBailarina(true);
      }
    }
    if(initAnimatedPerro){
      gsap.to(".animate-perro", {
        x: arPosPerro.x,
        delay: 6,
        duration: 3,
      });

      if(!initSoundPerro){
        playPerro();
        setInitSoundPerro(true);
      }
    }
  },[initAnimatedBailarina, initAnimatedPerro]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateBailarina()}
            {fnAnimatePerro()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page11Comp;
