import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: auto;
    }
    .speech-bubble-mini{
      margin-top: -390px;
      margin-left: 105px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 626px;
      height: 525px;
    }
    .speech-bubble-mini{
      margin-top: -410px;
      margin-left: 265px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -540px;
      margin-left: 360px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 65%;
    }
    .speech-bubble-mini{
      margin-top: -135px;
      margin-left: 270px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      top: 50px;
      width: 90%;
    }
    .speech-bubble-mini{
      margin-top: -160px;
      margin-left: 450px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      top: 65px;
      width: 78%;
    }
    .speech-bubble-mini{
      margin-top: -320px;
      margin-left: 530px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgBailarina = styled.div`
  position: absolute;
  height: auto;
  left: 50%;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 30px;
    margin-top: -4px;
    margin-left: 4px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 68px;
    margin-top: -9px;
    margin-left: 10px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 88px;
    margin-top: -14px;
    margin-left: 10px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 75px;
    margin-top: -5px;
    margin-left: 0px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 120px;
    margin-top: 65px;
    margin-left: 16px;
  }
  @media(min-width: 1920px){
    width: 140px;
    margin-top: 90px;
    margin-left: 16px;
  }
`
export const ImgBailarina = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgPerro = styled.div`
  position: absolute;
  height: auto;
  left: 0%;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 80px;
    margin-top: -60px;
    margin-left: 13px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 150px;
    margin-top: -120px;
    margin-left: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 250px;
    margin-top: -140px;
    margin-left: 36px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 200px;
    margin-top: -140px;
    margin-left: -6px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 250px;
    margin-top: -150px;
    margin-left: -6px;
  }
  @media(min-width: 1920px){
    width: 300px;
    margin-top: -150px;
    margin-left: -6px;
  }
`
export const ImgPerro = styled.img`
  width: 100%;
  height: 100%;
`
